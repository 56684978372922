
import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ISurvey } from '@/interfaces/survey'
import surveyModule from '@/store/survey'

@Component({
  components: { ValidationObserver, ValidationProvider }
})
export default class SurveyCreateView extends Vue {
  title = ''
  isActive = true

  async mounted() {
    await Promise.all([surveyModule.actionLoad()])
    this.reset()
  }

  reset() {
    this.title = ''
    this.isActive = false
  }

  cancel() {
    this.$router.back()
  }

  async submit() {
    const survey: ISurvey = {
      title: this.title,
      is_active: this.isActive,
      data_json: ''
    }

    await surveyModule.actionCreate(survey)
    this.$router.push({ name: 'admin-survey-all' })
  }
}
